const tsh = {
  parameters: [
    {
      name: 'TSH',
      displayName: 'TSH',
      id: 'tsh',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'fault',
      ],
      ranges: [{
        color: '#F7941F',
        description: 'TSH-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 0.28,
        upperLimitOperator: '<',
        unit: 'mIU/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#217359',
        description: 'TSH-normal',
        lowerLimit: 0.28,
        lowerLimitOperator: '>=',
        upperLimit: 4.20,
        upperLimitOperator: '<=',
        unit: 'mIU/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#EF4036',
        description: 'TSH-too-high',
        lowerLimit: 4.20,
        lowerLimitOperator: '>',
        upperLimit: 10,
        upperLimitOperator: '',
        unit: 'mIU/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
  ],
};

export default tsh;